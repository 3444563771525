import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UserProps } from './requireUser';

function getUser<P>(ComposedComponent: FC<P>): FC<P & Partial<UserProps>> {
  return (props => {
    const { user } = useSelector((globalState: any) => globalState.cms);

    return <ComposedComponent {...(props as P)} user={user} />;
  }) as FC<P & Partial<UserProps>>;
}

export default getUser;
