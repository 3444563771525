import React, { FC } from 'react';
import { SubmissionError } from 'redux-form';

import { Form } from '../../packages/formidable';
import inscription from '../../params/inscription.json';

const Inscription: FC = () => {
  const handleOnSubmit = async ({ password, ...values }: any) => {
    // const userData = new UserData();

    // const user = userData.initialize(values);

    try {
      // await userData.inscription({ ...user, password });
    } catch (error) {
      throw new SubmissionError({
        _error: (error as Error).message,
      });
    }
  };

  return (
    <Form
      datas={inscription}
      footerProps={{
        className: 'mt-6 flex justify-end',
      }}
      name="inscription"
      onSubmit={handleOnSubmit}
      submitProps={{ className: 'w-full', label: "S'inscrire" }}
    />
  );
};

export default Inscription;
