import {
  DocumentType,
  PriceType,
  ProduitType,
  TierType,
  UserType,
} from '@innedit/innedit-type';
import { PriceData } from 'packages/innedit';
import React, {
  ElementType,
  FC,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import Price from '../../../components/Price';

const ProduitPrice: FC<{
  as?: ElementType;
  doc: DocumentType<ProduitType>;
  onSelect: (price: DocumentType<PriceType>) => void;
  selected?: string;
  user?: DocumentType<UserType>;
}> = ({ as, doc, onSelect, selected, user }) => {
  const [prices, setPrices] = useState<DocumentType<PriceType>[]>();

  useEffect(() => {
    let isMounted = true;
    const featureData = new PriceData({
      espaceId: doc.espaceId,
      parentCollectionName: 'produits',
      parentId: doc.id,
    });

    featureData
      .find({
        wheres: {
          type: {
            operator: 'in',
            value: 'public',
          },
        },
      })
      .then(documents => {
        if (isMounted) {
          if (!documents || 0 === documents.length) {
            setPrices([]);
          } else {
            const sort = (
              a: DocumentType<PriceType>,
              b: DocumentType<PriceType>,
            ) => {
              const aData = a;
              const bData = b;

              if (aData && bData) {
                const aScheme = aData?.scheme;
                const bScheme = bData?.scheme;
                if (aScheme !== 'per_unit' && bScheme !== 'per_unit') {
                  if (!aData.tiers) {
                    return 1;
                  }
                  if (!bData.tiers) {
                    return -1;
                  }
                  // on prend le plus cher tier
                  const [aTier] = aData.tiers.sort(
                    (at: TierType, bt: TierType) => bt.amount - at.amount,
                  );
                  const [bTier] = bData.tiers.sort(
                    (at: TierType, bt: TierType) => bt.amount - at.amount,
                  );

                  return aTier.amount - bTier.amount;
                }
                if ('per_unit' === aScheme && bScheme !== 'per_unit') {
                  return 1;
                }
                if ('per_unit' === bScheme && aScheme !== 'per_unit') {
                  return -1;
                }
              }

              if (a.amount && b.amount) {
                return a.amount - b.amount;
              }

              if (!a.amount && b.amount) {
                return -1;
              }

              return 1;
            };

            // selection du meilleure tarif
            // tiers < standard et le plus petit amount
            const [d] = documents
              .filter(t => 'show' === t.displayMode)
              .sort(sort);

            // as FirebaseFirestore.DocumentSnapshot<PriceType>[];
            setPrices([d]);

            if (d) {
              onSelect(d);
            }
          }
        }

        return true;
      })
      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [doc, user]);

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const index = event.currentTarget.getAttribute('data-index');
    if (prices && null !== index) {
      onSelect(prices[parseInt(index, 10)]);
    }
  };

  if (!prices) {
    return null;
  }

  if (1 === prices.length) {
    const data = prices[0];

    return (
      <Price as={as} data={data} showDisplayMode={false} showType={false} />
    );
  }

  return (
    <>
      {prices.map((price, index) =>
        React.createElement(
          as || React.Fragment,
          { className: 'flex space-x-3 items-center', key: price.id },
          [
            <input
              key={`radio_${price.id}`}
              checked={price.id === selected}
              data-index={index}
              id={`radio_${price.id}`}
              onChange={handleOnChange}
              type="radio"
            />,
            <Price
              key={`price_${price.id}`}
              as="label"
              asProps={{
                htmlFor: `radio_${price.id}`,
              }}
              data={price}
              showDisplayMode={false}
              showType={false}
            />,
          ],
        ),
      )}
    </>
  );
};

export default ProduitPrice;
